// extracted by mini-css-extract-plugin
export var centeredTitle = "index-module--centered-title--d45f4";
export var childrenContainer = "index-module--children-container--b02be";
export var container = "index-module--container--83ea0";
export var dark = "index-module--dark--182ee";
export var innerContainer = "index-module--inner-container--ad9be";
export var left = "index-module--left--bc216";
export var light = "index-module--light--f1489";
export var noBottomPadding = "index-module--no-bottom-padding--209a7";
export var noRectangle = "index-module--no-rectangle--c4645";
export var noTopPadding = "index-module--no-top-padding--748a8";
export var overflowHidden = "index-module--overflow-hidden--bd3f2";
export var right = "index-module--right--08846";
export var subtitle = "index-module--subtitle--a6fd5";
export var tan = "index-module--tan--6ddd6";
export var textContainer = "index-module--text-container--94e17";
export var textContainerCustomWidth = "index-module--text-container-custom-width--51d66";